.custom-calendar {
  .rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #3965ea;
    box-shadow: none;
  }

  .rmdp-week-day {
    color: #00000061;
  }

  .rmdp-header-values {
    font-weight: bold;
  }

  /* remove space between month and year */
  .rmdp-header-values span {
    padding: 0;
  }

  .rmdp-day,
  .rmdp-week-day {
    height: 52px;
    width: 52px;
  }

  .rmdp-header {
    font-size: 1.125rem;
  }

  .rmdp-arrow {
    border: solid #00000061;
    border-width: 0 2px 2px 0;
    height: 10px;
    width: 10px;
  }

  .rmdp-arrow-container {
    height: 20px;
    width: 20px;
  }
  .rmdp-arrow-container.disabled {
    display: none;
  }

  @media (max-height: 450px), (max-width: 450px) {
    .rmdp-day,
    .rmdp-week-day {
      height: 38px;
      width: 38px;
    }
    .rmdp-header {
      font-size: 0.875rem;
    }
    .rmdp-arrow {
      height: 8px;
      width: 8px;
    }
    .rmdp-arrow-container {
      height: 14px;
      width: 14px;
    }
  }
}