@use 'InfractionDay.scss';

.flex-column {
  display: flex !important;
  flex-direction: column;
}

.flex-row {
  display: flex !important;
  flex-direction: row;
}

.flex-row-space-between {
  @extend .flex-row;
  justify-content: space-between;
  align-items: center;
}

.flex-column-space-between {
  @extend .flex-column;
  justify-content: space-between;
  align-items: center;
}

.flex-row-center {
  @extend .flex-row;
  justify-content: center;
  align-items: center;
}

.flex-row-flex-start {
  @extend .flex-row;
  justify-content: flex-start;
  align-items: center;
}

.container {
  @extend .flex-column;
  overflow-x: hidden;
}

.no-margin-no-padding {
  margin: 0 !important;
  padding: 0 !important;
}

#root {
  @extend .container;
  @extend .no-margin-no-padding;
  align-items: center;
  height: 100%;
}

.stretch-container {
  @extend .container;
  flex-grow: 1;
  align-items: stretch;
  justify-content: space-between;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.cta-container {
  @extend .container;
  align-items: center;
  justify-content: center;
  z-index: 500;
  flex-shrink: 0;
}

.bold {
  font-weight: bold !important;
}

.highlight {
  background-color: palegoldenrod;
  font-weight: bold;
}

.hidden {
  visibility: hidden;
}

.scrollable {
  overflow-y: auto;
}

.full-width-divider {
  position: relative;
  left: -16px;
  width: 100vw;
  overflow: hidden;
}

.vertical-form {
  width: 100% !important;
  padding-bottom: 24px;
}

.centered {
  margin: auto;
}

.vertical-form-text-input {
  margin-bottom: 2vh !important;
}

.timezone-select {
  @extend .vertical-form-text-input;
  margin-top: 2vh !important;
  text-align: left;
}

.day-info-inputs {
  @extend .flex-column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.header-container {
  align-self: center;
  width: 100vw;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.form-field-title {
  padding-bottom: 16px;
  padding-top: 32px;
}

.fade-in-image {
  padding: 0;
  position: relative;
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}

.fade-in-image:before {
  content: "";
  float: left;
  padding-top: 41%;

  @media (max-width: 500px) {
    padding-top: 120%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.PrivatePickersToolbar-root {
  background-color: #3284ff;
  color: white;

  .MuiTypography-root {
    color: white;
  }
}

// override dsfr style for mui compatibility
[target=_blank]:after {
  display: none !important;
}


// override dsfr tables
.fr-table {
  padding: 0;
  margin-bottom: 0;
}

.fr-table th {
  min-width: 125px;
}

.MuiSelect-nativeInput:disabled {
  opacity: 0;
}

[href]:not(.fr-breadcrumb__link, .fr-link, .fr-notice__link) {
  --underline-img: none;
}

video:not([href])[controls] {
  cursor: pointer;
}

.hr-unstyled {
  padding: 0;
  background-image: none;
}

.fr-footer,
.fr-follow {
  text-align: left; // style needed because text is centered in #root
  width: 100%;
}

.fr-footer__logo {
  max-height: 133px;
}

.MuiButton {
  &-contained:hover {
    --hover-tint: #0053b3;
  }

  &-outlined:hover,
  &-text:hover {
    --hover-tint: rgba(50, 132, 255, 0.04);
  }

  &-contained:active {
    --active-tint: #0053b3;
  }
}

.MuiTab-root:hover {
  --hover-tint: inherit;
}

.MuiTab-root:active {
  --active-tint: inherit;
}

.camera-box {
  --border-thickness: 8px;
  --border-color: #3284ff;   /* color of the border */
  --border-width: 60px;  /* width of border */
  --border-radius: 32px;  /* radius */
  z-index: 0;

  padding: var(--border-thickness); /* space for the border */

  position:relative;
}
.camera-box::before {
  content: "";
  position: absolute;
  inset: 0;
  background: var(--border-color,red);
  padding: var(--border-thickness);
  border-radius: var(--border-radius);
  -webkit-mask:
          linear-gradient(  0deg,#000 calc(2*var(--border-thickness)),#0000 0) 50% var(--border-thickness)/calc(100% - 2*var(--border-width)) 100% repeat-y,
          linear-gradient(-90deg,#000 calc(2*var(--border-thickness)),#0000 0) var(--border-thickness) 50%/100% calc(100% - 2*var(--border-width)) repeat-x,
          linear-gradient(#000 0 0) content-box,
          linear-gradient(#000 0 0);
  mask-composite: exclude;
}

.fr-checkbox-group {
  input[type=checkbox]:disabled{
    opacity: 0;
  }
}

.fr-card__title a:after {
  --icon-size: 1.5rem;
  bottom: 2rem;
  position: absolute;
  right: 2rem;
  margin-left: .5rem;
  -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0ibTE2LjE3MiAxMS01LjM2NC01LjM2NCAxLjQxNC0xLjQxNEwyMCAxMmwtNy43NzggNy43NzgtMS40MTQtMS40MTRMMTYuMTcyIDEzSDR2LTJoMTIuMTcyWiIvPjwvc3ZnPg==);
  mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0ibTE2LjE3MiAxMS01LjM2NC01LjM2NCAxLjQxNC0xLjQxNEwyMCAxMmwtNy43NzggNy43NzgtMS40MTQtMS40MTRMMTYuMTcyIDEzSDR2LTJoMTIuMTcyWiIvPjwvc3ZnPg==);
  background-color: currentColor;
  content: "";
  display: inline-block;
  flex: 0 0 auto;
  height: 1rem;
  height: var(--icon-size);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  vertical-align: calc(.375em - .5rem);
  vertical-align: calc((.75em - var(--icon-size))*.5);
  width: 1rem;
  width: var(--icon-size)
}

.fr-notice--success {
  background-color: var(--background-alt-green-emeraude);
  --idle: transparent;
  color: var(--text-label-green-emeraude);
}

.fr-notice--success .fr-notice__title:not([class^="fr-icon-"]):not([class*=" fr-icon-"]):not([class^="fr-fi-"]):not([class*=" fr-fi-"])::before {
  content: '';
  -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTEyIDIyQzYuNDc3IDIyIDIgMTcuNTIzIDIgMTJTNi40NzcgMiAxMiAyczEwIDQuNDc3IDEwIDEwLTQuNDc3IDEwLTEwIDEwem0tLjk5Ny02IDcuMDctNy4wNzEtMS40MTQtMS40MTQtNS42NTYgNS42NTctMi44MjktMi44MjktMS40MTQgMS40MTRMMTEuMDAzIDE2eiIvPjwvc3ZnPg==);
  mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTEyIDIyQzYuNDc3IDIyIDIgMTcuNTIzIDIgMTJTNi40NzcgMiAxMiAyczEwIDQuNDc3IDEwIDEwLTQuNDc3IDEwLTEwIDEwem0tLjk5Ny02IDcuMDctNy4wNzEtMS40MTQtMS40MTQtNS42NTYgNS42NTctMi44MjktMi44MjktMS40MTQgMS40MTRMMTEuMDAzIDE2eiIvPjwvc3ZnPg==);
  
}

.fr-notice--error .fr-notice__title:not([class^="fr-icon-"]):not([class*=" fr-icon-"]):not([class^="fr-fi-"]):not([class*=" fr-fi-"])::before {
  content: '';
  -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTEyIDJjNS41MjMgMCAxMCA0LjQ3NyAxMCAxMHMtNC40NzcgMTAtMTAgMTBTMiAxNy41MjMgMiAxMiA2LjQ3NyAyIDEyIDJabTEgMTNoLTJ2Mmgydi0yWm0wLThoLTJ2NmgyVjdaIi8+PC9zdmc+);
  mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTEyIDJjNS41MjMgMCAxMCA0LjQ3NyAxMCAxMHMtNC40NzcgMTAtMTAgMTBTMiAxNy41MjMgMiAxMiA2LjQ3NyAyIDEyIDJabTEgMTNoLTJ2Mmgydi0yWm0wLThoLTJ2NmgyVjdaIi8+PC9zdmc+);
}

.fr-notice--error {
  background-color: var(--background-alt-red-marianne);
  --idle: transparent;
  color: var(--text-label-red-marianne);
}

.fr-accordion  {

  &.success {
    background-color: var(--background-alt-green-emeraude);
    --hover: var(--background-contrast-green-emeraude);
    --idle: transparent;
    .fr-accordion__btn{
      color: var(--text-label-green-emeraude);

      &::before {
        content: '';
        -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTEyIDIyQzYuNDc3IDIyIDIgMTcuNTIzIDIgMTJTNi40NzcgMiAxMiAyczEwIDQuNDc3IDEwIDEwLTQuNDc3IDEwLTEwIDEwem0tLjk5Ny02IDcuMDctNy4wNzEtMS40MTQtMS40MTQtNS42NTYgNS42NTctMi44MjktMi44MjktMS40MTQgMS40MTRMMTEuMDAzIDE2eiIvPjwvc3ZnPg==);
        mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTEyIDIyQzYuNDc3IDIyIDIgMTcuNTIzIDIgMTJTNi40NzcgMiAxMiAyczEwIDQuNDc3IDEwIDEwLTQuNDc3IDEwLTEwIDEwem0tLjk5Ny02IDcuMDctNy4wNzEtMS40MTQtMS40MTQtNS42NTYgNS42NTctMi44MjktMi44MjktMS40MTQgMS40MTRMMTEuMDAzIDE2eiIvPjwvc3ZnPg==);
        --icon-size: 1.5rem;
        flex: 0 0 auto;
        display: inline-block;
        vertical-align: calc((0.75em - var(--icon-size)) * 0.5);
        background-color: currentColor;
        width: var(--icon-size);
        height: var(--icon-size);
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        margin-right: 0.5rem;
      }
    }

    .fr-accordion__btn[aria-expanded="true"] {
      background-color: var(--background-contrast-green-emeraude);
      --idle: transparent;
      --hover: var(--background-contrast-green-emeraude-hover);
      --active: var(--background-contrast-green-emeraude);
    }
  }

  &.info {
    background-color: var(--background-alt-blue-france);
    --idle: transparent;
    --hover: var(--background-open-blue-france);

    .fr-accordion__btn{
      color: var(--text-label-blue-france);

      &::before {
        content: '';
        -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTE5LjUgMi41aC0xNWMtMS4xIDAtMiAuOS0yIDJ2MTVjMCAxLjEuOSAyIDIgMmgxNWMxLjEgMCAyLS45IDItMnYtMTVjMC0xLjEtLjktMi0yLTJ6TTEzIDE3aC0ydi02aDJ2NnptMC04aC0yVjdoMnYyeiIvPjwvc3ZnPg==);
        mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTE5LjUgMi41aC0xNWMtMS4xIDAtMiAuOS0yIDJ2MTVjMCAxLjEuOSAyIDIgMmgxNWMxLjEgMCAyLS45IDItMnYtMTVjMC0xLjEtLjktMi0yLTJ6TTEzIDE3aC0ydi02aDJ2NnptMC04aC0yVjdoMnYyeiIvPjwvc3ZnPg==);
        --icon-size: 1.5rem;
        flex: 0 0 auto;
        display: inline-block;
        vertical-align: calc((0.75em - var(--icon-size)) * 0.5);
        background-color: currentColor;
        width: var(--icon-size);
        height: var(--icon-size);
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        margin-right: 0.5rem;
      }
    }
  }

  &.error {
    background-color: var(--background-alt-red-marianne);
    --idle: transparent;
    --hover: var(--background-open-red-marianne);

    .fr-accordion__btn{
      color: var(--text-label-red-marianne);

      &::before {
        content: '';
        -webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTEyIDJjNS41MjMgMCAxMCA0LjQ3NyAxMCAxMHMtNC40NzcgMTAtMTAgMTBTMiAxNy41MjMgMiAxMiA2LjQ3NyAyIDEyIDJabTEgMTNoLTJ2Mmgydi0yWm0wLThoLTJ2NmgyVjdaIi8+PC9zdmc+);
        mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCI+PHBhdGggZD0iTTEyIDJjNS41MjMgMCAxMCA0LjQ3NyAxMCAxMHMtNC40NzcgMTAtMTAgMTBTMiAxNy41MjMgMiAxMiA2LjQ3NyAyIDEyIDJabTEgMTNoLTJ2Mmgydi0yWm0wLThoLTJ2NmgyVjdaIi8+PC9zdmc+);
        --icon-size: 1.5rem;
        flex: 0 0 auto;
        display: inline-block;
        vertical-align: calc((0.75em - var(--icon-size)) * 0.5);
        background-color: currentColor;
        width: var(--icon-size);
        height: var(--icon-size);
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        margin-right: 0.5rem;
      }
    }

    .fr-accordion__btn[aria-expanded="true"] {
      background-color: var(--background-open-red-marianne);
      --idle: transparent;
      --hover: var(--background-open-red-marianne-hover);
      --active: var(--background-open-red-marianne-active);
    }
  }
}

.fr-btn--secondary.error {
  background-color: var(--background-alt-red-marianne);
  color: var(--background-action-high-red-marianne);
  box-shadow: inset 0 0 0 1px var(--border-action-high-red-marianne);
}

.fr-btn--icon-left[class^="fr-icon-"]::before, .fr-btn--icon-left[class*=" fr-icon-"]::before, .fr-btn--icon-left[class^="fr-fi-"]::before, .fr-btn--icon-left[class*=" fr-fi-"]::before {
  --icon-size: 1.3rem
}
.fr-btn--icon-right[class^="fr-icon-"]::after, .fr-btn--icon-right[class*=" fr-icon-"]::after, .fr-btn--icon-right[class^="fr-fi-"]::after, .fr-btn--icon-right[class*=" fr-fi-"]::after {
  --icon-size: 1.3rem
}

.fr-modal__footer {
  z-index: 2;
}

.MuiChip-filled.MuiChip-colorDefault {
  background-color: var(--background-action-low-blue-france)
}

main {
  scroll-margin-top: 200px;
}
